<template>
  <el-dialog :visible.sync="visible" :title="form && form._isAdd ? '新增价格组' : '编辑价格组'" append-to-body width="640px" @closed="form = null">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":" v-if="form">
      <el-form-item prop="ids" label="价格组">
        <quick-select v-model="form.ids" :label.sync="form.names" multiple url="api/shop/priceGroup" value-field="id" placeholder="选择价格组" filterable style="width: 100%;" />
      </el-form-item>
      <el-form-item prop="price" label="零售价格">
        <price-input v-model="form.price" :min="0" style="width: 180px;" />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button type="text" @click="visible = false">取消</el-button>
      <el-button :loading="saving" type="primary" @click="doSave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { editByDistributor } from "@/api/priceGroupSet";

export default {
  data() {
    return {
      visible: false,
      form: null,
      saving: false,
      rules: {
        ids: [
          { required: true, message: "请选择价格组" },
          {
            type: "array",
            min: 1,
            message: "请选择价格组",
            trigger: "change"
          }
        ],
        price: [{ required: true, message: "请填写零售价" }]
      }
    };
  },
  methods: {
    doSave() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.saving = true;
          let ds = {
            groupIds: this.form.ids,
            shoPriceGroupItem: [
              {
                goodsSkuId: this.form.goodsSkuId,
                price: this.form.price
              }
            ]
          };
          editByDistributor(ds)
            .then(res => {
              this.$notify({
                title: "设置价格组价格成功",
                type: "success",
                duration: 2500
              });
              this.$emit("complete");
              this.visible = false;
            })
            .finally(_ => {
              this.saving = false;
            });
        });
    },
    editPriceGroup() {
      let ds = {
        groupIds: this.form.ids,
        shoPriceGroupItem: [
          {
            goodsSkuId: this.form.goodsSkuId,
            price: this.form.price
          }
        ]
      };
      editByDistributor(ds)
        .then(res => {})
        .finally(_ => {
          this.saving = false;
        });
    },
    resetForm(form, extend) {
      this.form =
        form ||
        Object.assign(
          {
            ids: null,
            groupName: null,
            price: 0
          },
          extend || {}
        );
      this.visible = true;
    }
  }
};
</script>