import request from '@/utils/request'


// export function batchEditByArea(data) {
//   return request({
//     url: "api/shop/areaPrice/batch",
//     method: "post",
//     data
//   })
// }

// export function editByArea (data) {
//   return request({
//     url: 'api/shop/areaPrice',
//     method: 'post',
//     data
//   })
// }

// export function delByArea (ids) {
//   return request({
//     url: 'api/shop/areaPrice/del',
//     method: 'post',
//     data: ids
//   })
// }
// 新增  添加价格组中的商品价格
export function editByDistributor (data) {
  return request({
    url: 'api/shop/priceGroup/item',
    method: 'post',
    data
  })
}
// 删除
export function delByDistributor (ids) {
  return request({
    url: 'api/shop/priceGroup/item',
    method: 'delete',
    data: ids
  })
}